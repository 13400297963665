import React, { useEffect } from "react";
import Layout from "@/components/Layout";
import SEO from "@/components/seo";
import Partners from "@/components/Home/Partners";
import Breadcrumb from "@/components/Common/Breadcrumb";
import { useLocation } from "@reach/router";
import News from "@/components/News";
import BlogLayout from "@/components/Layout/BlogLayout";
import { useBlog } from "@/context/BlogContext";

const PostByTag = ({ params }: any) => {
  const { setTag, clearStorage } = useBlog();
  const { tag } = params;

  useEffect(() => {
    ["search", "category", "author"].forEach((el) => {
      clearStorage(el);
    });
    setTag(tag);
    window.localStorage.setItem("tag", tag);
  }, [tag]);

  return (
    <Layout>
      <SEO title="News" canonical={`${useLocation().host}`} />
      <Breadcrumb name="Latest Posts" pathName="Tag" />
      <BlogLayout>
        <News />
      </BlogLayout>
      <Partners />
    </Layout>
  );
};

export default PostByTag;
